/*
 * CB Internation Phone Input
 */

@use 'apricot-variable' as *;
@use '../mixins/font' as *;
@use '../mixins/glyph' as *;
@use '../mixins/breakpoint' as *;

.cb-int-phone {
  min-width: 256px;

  .cb-int-phone-container {
    position: relative;

    .cb-flag-dropdown {
      position: absolute;
      top: -1px;

      width: 68px;
      width: 100%;

      border-radius: $btn-border-radius-input;

      transition: height $transition-time-s ease-in-out;

      .cb-flag-selected {
        cursor: pointer;

        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        height: 48px;
        padding: 0 15px;

        color: $black1;
        text-decoration: none;

        border: 1px solid transparent;
        border-radius: $border-radius-input;

        .cb-flag {
          width: 40px;
          text-align: center;
        }

        &:visited {
          color: $black1;
        }

        &:focus {
          cursor: default;

          background-color: $white;
          border: 1px solid $input-focus;
          outline: 0;
          box-shadow: inset 0 0 1px 0.5px rgb(48 73 207 / 100%);
        }

        .cb-dropdown-toggle {
          font-size: $font-size-small !important;
          line-height: $line-height-small-16;
          text-align: center;
          text-decoration: none !important;

          transition: all $transition-time-s ease-in-out;

          &:hover,
          &:active,
          &:focus {
            text-decoration: none;
          }

          .cb-glyph,
          .cb-icon {
            margin-left: 8px;
            font-size: 0.75rem;
            color: $black1;
          }
        }
      }

      .cb-line {
        position: absolute;
        top: 3px;
        left: 92px;

        display: block;

        width: 1px;
        height: 43px;

        border-right: 1px solid $gray4;
      }

      .cb-country-list {
        position: absolute;
        z-index: 3;
        top: 47px;
        left: 0;

        overflow: hidden auto;
        display: none;

        width: 100%;
        max-height: 200px;
        margin: 0;
        padding: 0 0 12px;

        list-style: none;

        background-color: $white;
        border: 1px solid transparent;
        border-radius: $border-radius-input;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        li {
          > a {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            width: 100%;
            padding: 12px 24px;

            font-size: $font-size-small;
            line-height: $line-height-small-16;
            color: $black1;
            text-decoration: none !important;
            white-space: nowrap;

            opacity: 0.4;

            transition: all $transition-time-s ease-in-out;

            @include media-breakpoint-down('sm', $grid-breakpoints) {
              padding: 16px 24px;
            }

            &:hover,
            &:active,
            &:focus {
              color: $black1;

              opacity: 1;
              background-color: $gray5;
              outline: 0;
              box-shadow: inset 2px 0 0 0 $black1;
            }

            span {
              font-size: $font-size-small;
              line-height: $line-height-small-16;
            }

            &.cb-selected {
              box-shadow: inset 2px 0 0 0 $black1;
            }
          }
        }
      }

      &.cb-open {
        height: 248px;
        box-shadow: 0 0 24px rgb(0 0 0 / 20%);

        .cb-flag-selected {
          background-color: $white;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }

        .cb-line {
          display: none;
        }

        .cb-country-list {
          display: block;
        }

        &.cb-revers {
          top: -200px;

          .cb-flag-selected {
            top: 199px;
            border-radius: $border-radius-input;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }

          .cb-country-list {
            top: 0;
            border-radius: $border-radius-input;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }

      &.cb-focus {
        .cb-line {
          display: none;
        }
      }
    }

    input {
      padding-left: 104px !important;
    }

    .cb-open {
      + input {
        border-color: $white;
      }
    }
  }

  &.cb-input-condensed,
  &.cb-condensed {
    .cb-flag-dropdown {
      .cb-flag-selected {
        height: 40px;
      }

      .cb-country-list {
        top: 38px;
      }
    }

    .cb-line {
      height: 35px !important;
    }
  }

  &.cb-disabled {
    .cb-flag-dropdown {
      a {
        .cb-flag {
          opacity: 0.4;
          filter: grayscale(100%);
        }

        .cb-dropdown-toggle {
          .cb-glyph,
          .cb-icon {
            color: $cb-disabled-font-color !important;
          }
        }

        &:focus {
          cursor: not-allowed !important;
          background-color: transparent !important;
          border: 1px solid transparent !important;
          box-shadow: none !important;
        }

        &:hover {
          cursor: not-allowed !important;
        }
      }
    }
  }
  
  .cb-flag-dropdown {
    a {
      &.cb-disabled {
      .cb-flag {
        opacity: 0.4;
        filter: grayscale(100%);
      }

      .cb-dropdown-toggle {
        .cb-glyph,
        .cb-icon {
          color: $cb-disabled-font-color !important;
        }
      }

      &:focus {
        cursor: not-allowed !important;
        background-color: transparent !important;
        border: 1px solid transparent !important;
        box-shadow: none !important;
      }

      &:hover {
        cursor: not-allowed !important;
      }
    }
    }
  }

  /* autofill */
  input:-webkit-autofill {
    animation-name: on-auto-fill-start-int-phone;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
  }
}

/*  extra treatment for floating label */
.cb-floating-label {
  &.cb-int-phone {
    label {
      z-index: 2;
      justify-content: flex-start;
      width: calc(100% - 32px);
    }

    .cb-flag-dropdown {
      display: none;
    }

    input {
      padding-left: 16px;
    }

    .cb-value-fl,
    .cb-focus-fl {
      justify-content: center;
      width: auto;

      + .cb-int-phone-container {
        .cb-flag-dropdown {
          display: block;
        }

        input {
          padding-left: 84px;
        }
      }
    }
  }
}

@keyframes on-auto-fill-start-int-phone {
  from {
    font-size: inherit;
  }

  to {
    font-size: inherit;
  }
}
